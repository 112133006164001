<template>
    <div>
        <!-- <el-card>
        <nav class="input" style="width: auto">
            <el-input v-model="search.name" size="medium" placeholder="商品名" />
        </nav>
        <nav class="input" style="width: 150px">
            <el-button size="small" @click="reset">重置</el-button>
            <el-button type="primary" size="small" @click="subimt">{{
            '搜索'
          }}</el-button>
        </nav>
        </nav>

        </nav>
    </el-card> -->
        <el-card style="margin-top: 10px">
            <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                <el-table-column prop="id" label="ID" />
                <!-- <el-table-column prop="id" label="商品图片">
                <template slot-scope="{row}">
                    <el-image style="width: 80px" :src="baseurl + yu + row.picture" :preview-src-list="[baseurl + yu + row.picture]" />
                </template>
            </el-table-column> -->
                <el-table-column prop="name" label="名称" />
                <el-table-column prop="type" label="类型">
                    <template slot-scope="{ row }">
                        {{ row.type == 0 ? "VIP会员" : "人脉卡" }}
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="时间类型">
                    <template slot-scope="{ row }">
                        {{ row.timeType == 0 ? "月" : row.timeType == 1 ? "季" : "年" }}
                    </template>
                </el-table-column>
                <el-table-column prop="price" label="价格" />
                <el-table-column prop="viewContactDay" label="查看联系方式次数">
                    <template slot-scope="{ row }"> {{ row.viewContact }}次 </template>
                </el-table-column>
                <el-table-column prop="sendCardDay" label="发送名片次数">
                    <template slot-scope="{ row }"> {{ row.sendCardDay }}/天 </template>
                </el-table-column>
                <el-table-column prop="sendCardDay" label="合作次数">
                    <template slot-scope="{ row }"> {{ row.cooperationApplyDay }}/天 </template>
                </el-table-column>
                <!-- <el-table-column prop="sendCardDay" label="每天发送名片次数" />
            <el-table-column prop="sendCardDay" label="每天申请合作次数" />
            <el-table-column prop="sendCardDay" label="每天查看联系方式次数" /> -->
                <!-- <el-table-column prop="price" label="价格" /> -->

                <!-- <el-table-column label="操作">
                <template slot-scope="{row}">
                    <el-button type="text" icon="el-icon-edit" size="mini" style="color:skyblue" @click="editor(row)">编辑</el-button>
                    <el-button type="text" icon="el-icon-delete" size="mini" style="color:red" @click="deletes(row)">删除</el-button>
                    <el-button type="text" icon="el-icon-s-promotion" size="mini" style=" color: pink;" @click="shelves(row)">{{row.status == 0 ? '下架' : '上架'}}</el-button>
                </template>
            </el-table-column> -->
            </el-table>
            <el-pagination
                :current-page="search.page"
                background
                :page-sizes="[3, 5, 10, 15]"
                :page-size="search.pageSize"
                layout="total, prev, pager, next"
                :total="total"
                @current-change="handleCurrentChange"
            />
        </el-card>
    </div>
</template>

<script>
import list from "/src/mixin/list.js";

export default {
    mixins: [list],
    data() {
        return {
            search: {
                name: "",
                page: 1,
                pageSize: 10,
            },
            form: {
                name: "",
                page: 1,
                pageSize: 10,
            },
        };
    },
    created() {
        this.getlist();
    },
    methods: {
        add() {
            this.$router.push("/product_add");
        },
        toList(row) {
            this.$router.push({
                path: "/product_record",
                query: {
                    id: row.id,
                },
            });
        },
        editor(row) {
            this.$router.push({
                path: "/product_add",
                query: {
                    id: row.id,
                },
            });
        },
        async deletes(row) {
            try {
                await this.$confirm("此操作将永久删除, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                });
                let { data: res } = await this.$http.delete("/admin/BonusMall/productDel", {
                    params: {
                        id: row.id,
                    },
                });
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    if (this.total % 10 == 1) {
                        this.search.page--;
                    }
                    this.getlist();
                }
            } catch (error) {
                this.$message({
                    type: "info",
                    message: "已取消删除",
                });
            }
        },
        async shelves(row) {
            // console.log(row.status);
            const { data: res } = await this.$http.post("/admin/BonusMall/updateProductStatus", {
                id: row.id,
                status: row.status ? 0 : 1,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            }
        },
        handleCurrentChange(e) {
            this.search.page = e;
            this.form.page = e;
            this.getlist(this.form);
        },
        async getlist(val) {
            const form = val || this.search;
            let { data: res } = await this.$http.get("/admin/UserCard/getList", {
                params: {
                    ...form,
                },
            });
            this.list = res.data.list;
            this.total = res.data.totalCount;
        },
        subimt() {
            this.form = {
                ...this.search,
            };
            this.getlist();
        },
    },
};
</script>

<style lang="less" scoped></style>
